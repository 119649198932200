import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "vant/lib/index.css";
import { Button, Image as VanImage, Toast, Dialog, Cell, Form, CellGroup, Field, Divider, Icon, Tag } from "vant";
Vue.config.productionTip = false
Vue.use(Button);
Vue.use(VanImage);
Vue.use(Toast);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Field);
Vue.use(Form);
Vue.use(Divider);
Vue.use(Icon);
Vue.use(Dialog);
Vue.use(Tag);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
