import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/wx/coupon/default',
    name: 'Coupon',
    component: () => import('../views/coupon.vue')
  },
  {
    path: '/verfiy',
    name: 'Verify',
    component: () => import('../views/verfiy.vue')
  },
  {
    path: '/qy/default',
    name: 'qy',
    component: () => import('../views/qy.vue')
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import('../views/order.vue')
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('../views/error.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
